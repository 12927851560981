export const pageSettingsLsgPalava = {
  landingPage: {
    title:
      "LSG Lodha World School - CBSE School in Dombivli | Holistic Education For Future Leaders",
    metaTags: [
      {
        name: "description",
        content:
          "At LSG Lodha World School in Palava, Dombivli is one of those schools where we nurture the mind, body, and spirit of students to build leaders of tomorrow. We are affiliated to CBSE board.",
      },
      {
        property: "og:title",
        content:
          "LSG Lodha World School - CBSE School in Dombivli | Holistic Education For Future Leaders",
      },
      { property: "og:site_name", content: "LodhaWorldSchoolLSG" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/lsgpalava",
      },
      {
        property: "og:description",
        content:
          "At LSG Lodha World School in Palava, Dombivli is one of those schools where we nurture the mind, body, and spirit of students to build leaders of tomorrow. We are affiliated to CBSE board.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairpersonLsg.jpg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/lsgpalava",
  },
  ourStory: {
    title: "Explore Lodha World School's Remarkable Journey since 2008",
    metaTags: [
      {
        name: "description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      {
        property: "og:title",
        content: "Explore Lodha World School's Remarkable Journey since 2008",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      { property: "og:url", content: "https://www.lodhaworldschool.com/" },
      {
        property: "og:description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairperson.jpg",
      },
    ],
  },
  vision: {
    title: "Lodha World School - Vision & Mission",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us!",
      },
      {
        property: "og:title",
        content: "Lodha World School - Vision & Mission",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/inspireuscurriculum",
      },
      {
        property: "og:description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us! ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  values: {
    title:
      "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      {
        property: "og:title",
        content:
          "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/values",
      },
      {
        property: "og:description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  people: {
    title: "Meet the Leaders Behind Lodha World School's Vision",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      {
        property: "og:title",
        content: "Meet the Leaders Behind Lodha World School's Vision",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/people",
      },
      {
        property: "og:description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  inspireUsCurriculum: {
    title: "Lodha World School Custom Curriculum - Inspire Us",
    metaTags: [
      {
        name: "description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum, specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      {
        property: "og:title",
        content: "Lodha World School Custom Curriculum - Inspire Us",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/vision",
      },
      {
        property: "og:description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum is specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  results: {
    title: "Lodha World Schools - Results",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      {
        property: "og:title",
        content: "Lodha World Schools - Results",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/results",
      },
      {
        property: "og:description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  skillDevelopment: {
    title: "Lodha World School - Skill Development Beyond the Classroom",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Skill Development Beyond the Classroom",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/skilldevelopent",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  leadershipDevelopment: {
    title:
      "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      {
        property: "og:title",
        content:
          "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/leadershipdevelopment",
      },
      {
        property: "og:description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  awards: {
    title: "Lodha World School - Awards and Achievements",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Awards and Achievements",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/awards",
      },
      {
        property: "og:description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  testimonials: {
    title: "Lodha World School - Testimonials From Parents & Students",
    metaTags: [
      {
        name: "description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Testimonials From Parents & Students",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/testimonials",
      },
      {
        property: "og:description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  staff: {
    title:
      "Lodha World School - Career Opportunities & Benefits | Join Our Team",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - Career Opportunities & Benefits | Join Our Team",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/career",
      },
      {
        property: "og:description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  alumni: {
    title: "Lodha World School - Alumni: Forever Part Of Our Community",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Alumni: Forever Part Of Our Community",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/alumni",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
  lwsMarkers: {
    title: "Lodha World School - Markers",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Markers",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/lwsmarkers",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
  },
};

export const lightBgWebLsg = {
  imageSource: "/assets/lsgbg1.gif",
};

export const sidebarLsgPalava = {
  fb: "https://www.facebook.com/LodhaWorldSchoolLSG/", // phone pending
  linkedin: "https://www.linkedin.com/company/lodha-world-school/",
  insta: "https://instagram.com/lodhaworldschool?igshid=YmMyMTA2M2Y=",
  email: "mailto:contact_lsg@lodhaworldschool.com",
  admission: "mailto:admission_lsg@lodhaworldschool.com",
  emailname: "contact_lsg@lodhaworldschool.com",
  admissionname: "admission_lsg@lodhaworldschool.com",
  phone: "tel:9321450245",
  phonename: "+91 9321450245",
};
export const sidebarlinkLsgPalava = [
  {
    logo: "/assets/board1.png",
    link: "https://www.cbse.gov.in/",
    width: "80px",
  },
];

export const websiteSchemaLsgPalava = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Lodha World School, LSG, Palava",
  url: "https://www.lodhaworldschool.com/lsgpalava/",
  logo: "https://www.lodhaworldschool.com/assets/logo.svg",
};
export const personSchemaLsgPalava = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  accountablePerson: [
    {
      "@type": "Person",
      name: "Ms. Meera Ramanathan",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people6.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, LSG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Hempunj Ohri",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people18.png",
      jobTitle: "Coordinator - Pre Primary",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, LSG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Firdos Kamil Sayyad",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people19.png",
      jobTitle: "Coordinator - Grades I & II",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, LSG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Chinmayee Gothoskar",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people20.png",
      jobTitle: "Coordinator - Grades III-V",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, LSG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Anjali Kurup",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people21.png",
      jobTitle: "Coordinator - Grades VI-VIII",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, LSG, Palava",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Anshuman Malviya",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people22.png",
      jobTitle: "Coordinator: Grades IX & X",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School, LSG, Palava",
      },
    },
  ],
};

export const lightBgMobileLsg = {
  imageSource: "/assets/lsgbg1mobile.gif",
};

export const peopleLsgPalava = [
  {
    imageSource: "/assets/people6.jpg",
    name: "Dr. Rashmirekha Saha",
    title: "Principal",
  },
  {
    imageSource: "/assets/people18.png",
    name: "Ms. Hempunj Ohri",
    title: "Coordinator - Pre-School",
  },
  {
    imageSource: "/assets/people19.png",
    name: "Ms. Firdos Kamil Sayyad",
    title: "Coordinator - Class I & II",
  },
  {
    imageSource: "/assets/people20.png",
    name: "Ms. Chinmayee Gothoskar",
    title: "Coordinator - Class III-V",
  },
  {
    imageSource: "/assets/people21.png",
    name: "Ms. Anjali Kurup",
    title: "Coordinator - Class VI-VIII",
  },
  {
    imageSource: "/assets/people22.png",
    name: "Ms. Anshuman Malviya",
    title: "Coordinator - Class IX-XII",
  },
];
export const testimonialsLsgPalava = [
  {
    name1: "Arshi Chawla, Student, Class 10",
    name2: "",
    title:
      "Life at LWS LSG is much different as compared to other schools. We often use design thinking and thinking routines to solve school issues that we face. Every student is encouraged and motivated to take part in the ongoing activities. There is a unique bond shared between students and teachers. Our teachers and counselors not only mentor the students but also let us express our feelings and emotions freely.",
  },
  {
    name1: "Vandit Joshi, Student, Class 8",
    name2: "",
    title:
      "Student life at LWS, LSG is fun. Unlike many schools who focus only on studies, our school focuses both on studies as well as our physical and mental health. There are many Co-Curricular activities, like Annual days, Sports Days, and HotFut. In HOT FUT we get to choose what game we want to get better at. This actually helps us get refreshed at the end of the day. On top of that, the school anchors the way the teachers teach the students, they teach us the way we like, using examples most students understand to help us study. We also appreciate the effort taken by the teachers in making presentations to teach us.",
  },
  {
    name1: "Navika Thakur, Class 4",
    name2: "",
    title:
      "I love extracurricular activities at LWS LSG. We have table tennis, lawn tennis, badminton, football, swimming etc. These activities are as important as academics. It gives me confidence as it helps me to learn teamwork, behavioural skills & life skills.",
  },
  {
    name1: "Ms. Jayshri Jalwadgi, Pre-primary Teacher",
    name2: "",
    title:
      "I have been associated with Lodha World School LSG, for the last 3 years. I could not ask for a better school to be a part of! I take great pride in being a part of Inspire US, a specially designed in-house curriculum. The curriculum not only provides a high-quality learning program for students but also a detailed set of guidelines to the teachers. I am happy to be a part of an institution that strives to take eloquent steps to transform our core philosophy and value into practical action to nurture children to become the leaders of tomorrow. I look forward to many more years of fruitful association with LWS LSG.",
  },
  {
    name1: "Mrs. Shikha Gupta",
    name2: "parent of Anishka and Prisha Gupta",
    title:
      "We are proud parents associated with the school since its founding in 2019. I express my heartfelt gratitude to the school for providing my daughters with a foundation to face the real world and the opportunities to participate in various competitions resulting in their overall development. HRP is the best initiative by school which has made my daughter start loving books more than TV. We feel so fortunate to have our kid's teacher as their first mentor. We are grateful and happy beyond words for the growth and confidence we have seen in them. Thank you to teachers for their encouragement, patience and care. Special Thanks to Meera ma’am for being such an impactful leader. Strong leadership is the foundation of the school's success and without her that would not be possible. We are truly blessed to be part of this 'WORLD’.",
  },
  {
    name1: "Ms. Radha Mohan",
    name2: "parent of Avika Mohan",
    title:
      "LWS is the second home for my Daughter, we are blessed to have it close to where we live. My daughter is truly happy when at LWS. They offer a creative and supportive environment. There’s real alchemy in that very special place. The ingredients are all there – the endless kindness, the thoughtfulness, the imagination, the fun, the skill and technique, the reassuring blend of modernity and glorious timelessness. It is a little jewel box of a school that is overflowing with love, goodwill, simple and traditional values, and joy. Teachers are kind, sweet, patient, understanding, fun, imaginative, creative. LWS is truly one of a kind and deserves its status as a most beloved and revered school in Lakeshore.",
  },
  {
    name1: "Dr. Mrs. Anamika Singh",
    name2: "parent of Shreyansh Kumar",
    title:
      "We have been associated with the school since March 2021. The level of education is outstanding. Students’ health and safety are always a priority at school. We are happy that our children are in the hands of such a skilled and professional team, which helps build their own identity and guides them towards the path of success. Thank you for planting the seeds of knowledge and creating the leaders of tomorrow.",
  },
  {
    name1: "Mrs. Lakshmi Lavekar",
    name2: "parent of Nandini Lavekar",
    title:
      "My daughter started this beautiful ride at Lodha World School, LSG in 2020. The school has become our extended family because at all times, the Principal Ms Meera Ramanathan and her extremely motivated force of well-trained teachers, counsellors, sports mentors, and the non-teaching staff have been above and beyond my expectations as a parent. The way the LWS team is moving ahead in achieving their overarching goal of creating Leaders of Tomorrow is truly commendable.",
  },
  {
    name1: "Mr. Rajneesh Randhawa",
    name2: "parent of Akshaj Randhawa",
    title:
      "We have been associated with LWS LSG since 2019 i.e right from the beginning since the school opened. I have observed personally that the learning and education of my child is progressive with overall skill development. I also observed that the school considers the students’ health and safety as its prime responsibility, especially after the COVID-19 pandemic when physical classes resumed. Teachers are very cooperative and supportive with parents and students. I realize my son feels very comfortable with all teachers and his classmates. It's possible only because of the vision, culture and discipline Dr. Rashmirekha nurtured in LWS LSG. Overall, the student-centric approach makes the school unique and facilitates the holistic development of kids.",
  },
  {
    name1: "Mr. Gurwinder S Badesha",
    name2: "parent of Gunveer S Badesha",
    title:
      "I prefer Lodha World School for its visual presentation and their focus on innovative methods of educating children rather than mugging up. They are proactive in taking measures for children’s health & safety. I also appreciate their involvement of parents in daily school activities - painting, craft, activities to be taken home - making it a joint effort of both the school and the parents. Overall, I like their soft approach towards education instead of unnecessary aggressive strictness, behaviour, and a stressful environment. Unique about Lodha World School is participative management, which leads students to open up and ask questions from the mind freely.",
  },
  {
    name1: "Mr. Anupam Pahari",
    name2: "parent of Anushree Pahari",
    title:
      "I would like to say a big thank you to each and every teacher at Lodha World School for ensuring that our children’s studies have not been impacted because of the lockdown in the academic year 2021. It was overwhelming to see how quickly and efficiently the school adapted to the new way of teaching and learning. During the online sessions, each and every student was given a chance to speak and express themselves, and the feedback was handled positively. In addition, I would like to mention about the 'Home Reading Program' which is an innovative idea to improve a child’s storytelling capability. The unique part of Lodha World School is the student's safety, care for my child and the infrastructure setup for both online and offline modes of teaching.",
  },
  {
    name1: "Mrs. Ashwini Narayan",
    name2: "",
    title:
      "This is our twin children’s 4th year with the LWS family. Lodha World School has been the perfect fit for our kids. A sense of security and satisfaction is what we get from the pleasant environment of the school. The teachers and leadership are highly competent and committed to the complete development of the kids. Which is definitely nurturing 'LEADERS OF TOMORROW' The innovative ideas (HRP) and new examination pattern (CAT), which looked cumbersome in the beginning, are commendable and have helped the kids develop ideas, frameworks, applications and more importantly, problem-solving skills.",
  },
];
export const resultsLsgPalava = [];

export const homeAwardsLsgPalava = {
  imageSource: "/assets/starawards.png",
  id: "awardsLsg",
};
export const faqLsg = [{}];

export const starAwardsLsgPalava = [
  {
    imageSource: "/assets/awards.png",
    name: "#6 in Thane, EducationWorld India Rankings 2023-24",
    // link: "https://timesschoolsurvey.com/mumbai/",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Excellence in Tech-Enabled Education Infrastructure, Eldrok India K-12 Summit 2023",
    // link: "https://www.freepressjournal.in/education/fpjs-mumbai-schools-survey-2022-take-a-look-at-top-performers",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Outstanding Learning & Curriculum, Free Press Journal Survey 2023",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Tech-Savvy Teaching, Free Press Journal Survey 2023",
  },
  {
    imageSource: "/assets/awards.png",
    name: "#1 in Holistic Education, Maharashtra School Merit Awards 2023",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Top Emerging School in Mumbai, Times School Survey 2022 and 2023",
  },
];

export const chairpersonMsgLsgPalava = {
  imageSource: "/assets/chairpersonLsg.jpg",
  alt: "LSG Lodha World School, Palava - Principal's Message",
  imgTitle: "Principal's Message about LSG Lodha World School, Palava",
  name: "Dr. Rashmirekha Saha",
  title:
    "Our school aims to encompass the holistic development of every individual by nurturing the intellect, physical well-being, and spiritual essence.",
  nameMain: "-Dr. Rashmirekha Saha",
  cta: "Principal’s Message",
  dialogText1:
    "At Lodha World School, we believe in fostering an environment that not only hones intellectual capabilities but also nurtures the core of each child. In our pursuit of academic excellence, we stand committed to embracing inclusiveness, teamwork, and collaboration. These values are the cornerstone of our philosophy on a mission to prepare our students not just for success in the classroom but also in life.",
  dialogText2:
    "Our dedicated faculty are committed to nurturing the leaders of tomorrow through an approach that values collaboration, creativity, and a well-rounded perspective. In our classrooms, design thinking seamlessly integrates into our teaching philosophy, encouraging students to approach challenges with a critical yet imaginative mindset. Through carefully crafted experiences, we aim to instill problem-solving skills that extend beyond textbooks.",
  dialogText3:
    "Emotional Wellness is woven into the fabric of our educational practices, promoting resilience, empathy, and a sense of well-being. Environmental Consciousness and Symbiotic Learning are integral to our ethos, subtly shaping our students into compassionate, innovative, and environmentally conscious individuals.",
  dialogText4:
    "My vision for Lodha World School is to build on the strong foundation. We will continue to celebrate the uniqueness of each child and empower them to discover their strengths.",
  dialogText5:
    "As we move forward, I am confident that with your support, we will achieve new heights of success to produce leaders of tomorrow. I look forward to collaborating with you all, parents, teachers and students, to make this journey a truly enriching and memorable experience.",
};
export const landingLsgPalava = {
  imageSource: "/assets/palava.jpg",
  title1: "LSG Lodha World School, Palava - ",
  title2: "Building empathetic Leaders of Tomorrow",
};

export const middleYearsLsgPalava = [
  {
    imageSource: "/assets/inspireUsImg.png",
    alt: "Foundation & Middle Years - LSG Lodha World School, Palava",
    imgTitle: "Foundation & Middle Years at LSG Lodha World School, Palava",
    name: "Foundation & Middle Years",
    title:
      "At LWS, we redefine conventional learning. Our globally researched curriculum is crafted to deliver academic excellence.",
    cta: "Inspire Us Curriculum",
  },
];

export const seniorYearsLsgPalava = {
  imageSource: "/assets/academicsImgLsg.png",
  alt: "Senior Years - LSG Lodha World School, Palava",
  imgTitle: "Senior Years at LSG Lodha World School, Palava",
  name: "Senior Years",
  title:
    "We are affiliated to the Central Board of Secondary Education (CBSE). We train our young leaders in accordance with its rigorous standards.",
  cta: "CBSE Curriculum",
};

export const seniorpara1LsgPalava = {
  para1:
    "The CBSE vision is to offer choices to learners based on their interest, aptitude, inclination and ability, making no distinctions between subject streams. CBSE offers learners stress-free education with only 5 compulsory subjects in the senior years (Class IX-XII). The syllabus of the Secondary and Senior Secondary in CBSE is well-planned and has adequate depth of knowledge. The pedagogy is aimed at drawing out specific skill outcomes targeted to be taught using the content thus ensuring competency-based learning.",
  para2:
    "Most common entrance tests are based on the CBSE syllabus and assessment pattern, giving CBSE students an edge over other boards.",
  title1: "Salient Features of CBSE",
  title2: "Best practices followed at LWS LSG",
  title3: "Subjects offered - CBSE Curriculum:",
  foot1: "*Compulsory subjects with only school-based internal assessment",
  foot2: "**subsumed in Health and Physical Education",
};

export const seniorpara2LsgPalava = [
  {
    name: "Provide ample scope for holistic (physical, intellectual, social) development of students",
  },
  {
    name: "Emphasis on constructivist approach with importance to hands-on experience rather than rote learning",
  },
  {
    name: "Focus on competency-based learning encouraging skill development embedded in the subject content",
  },
  {
    name: "Promote critical and creative thinking aligned to the 21st Century skills in classrooms",
  },
  {
    name: "Integrates innovations in pedagogy with technological innovations (ICT) to keep pace with global trends in various disciplines - experiential learning, sports & art-integrated learning, toy-based pedagogy, storytelling, gamification, etc.",
  },
];

export const seniorpara3LsgPalava = [
  {
    name: "Experiential pedagogical approaches",
  },
  {
    name: "Adopting principles of universal design of learning (UDL) to maintain an inclusive approach in classroom teaching learning",
  },
  {
    name: "Tech integration in senior years",
  },
  {
    name: "SEWA - an essential component of the CBSE curriculum - activities include promoting reading awareness and literacy-based activities for students in the village school, cyber safety awareness, welfare of stray animals",
  },
  {
    name: "Dedicated 4 hours of sports in the school timetable",
  },
  {
    name: "Career counselling for Class IX onwards + choice of virtual internship programs",
  },
];

export const seniorpara4LsgPalava = [
  {
    name: "Languages 1",
  },
  {
    name: "Languages 2",
  },
  {
    name: "Social Science",
  },
  {
    name: "Mathematics",
  },
  {
    name: "Science",
  },
  {
    name: "Elective Subjects (optional)",
  },
  {
    name: "Health and Physical Education*",
  },
  {
    name: "Work Experience**",
  },
  {
    name: "Art Education*",
  },
];

export const ourstoryLsgPalava = [
  {
    name: "Our school marks the third chapter in the Lodha World School journey, which began in the heart of Thane in 2008. In June 2019, Lodha World School set up its first CBSE-affiliated campus at Lakeshore Greens, Palava, with the promise of providing education to more than 2500 learners in the neighbourhood. The vision of our school is to nurture the mind, body and spirit of the young learners who walk into our doors. Equipped with well-trained faculty and state-of-the-art infrastructure, our school provides an environment that facilitates the transformation from curious learners to confident leaders of tomorrow. The LSG Community - across its learners, teachers, parents - works together to achieve the LWS motto of creating responsible and empathetic global citizens.",
  },
];

export const visionLsgPalava = [
  {
    title:
      "To nurture individuals sound of body, mind and spirit, and equip them to emerge as Leaders of Tomorrow.",
  },
];

export const missionThane = [
  {
    id: "vslideThane-1",
    text: "To provide quality school education, progressive in challenging pupils to take advantage of the emerging global opportunities.",
  },
  {
    id: "vslideThane-2",
    text: "To nurture pupils to make them caring, competent and contributing members of society.",
  },
  {
    id: "vslideThane-3",
    text: "To establish pupil-centred environments for academics as well as extracurricular opportunities that nourish self-efficacy and self-esteem.",
  },
  {
    id: "vslideThane-4",
    text: "To stimulate critical thinking and commitment to excellence that gradually lead to success in higher education and career.",
  },
  {
    id: "vslideThane-5",
    text: "To create a school community that reflects and respects ethnic, racial, religious and national diversity.",
  },
];

export const inspireUsLsgPalava = {
  title1: "Foundation &",
  title2: "Middle Years",
};
export const academicsLsgPalava = {
  imageSource: "/assets/symbioticSlide5.jpg",
  title: "Senior Years",
  name: "We are affiliated to the Central Board of Secondary Education (CBSE). We train our young leaders in accordance with its rigorous standards.",
  cta: "CBSE Curriculum",
};
export const joinLwsLsgPalava = {
  title: "Join Lodha World School, LSG",
  imageSource: "/assets/joinLsg.png",
};
export const valuesLsgPalava = [
  {
    id: "houses-1",
    title: "Atulya:",
    color: "#9C2020",
    subtitle: "the incomparable.",
    description:
      "A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
    quote:
      "Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives",
  },
  {
    id: "houses-2",
    title: "Advaita:",
    color: "#276440",
    subtitle: "the one and only one.",
    description: "A leader has an unchanging, singular vision of the goal.",
    quote:
      "Inspiration: Shri APJ Abdul Kalam - unique peerless vision for our country and society",
  },
  {
    id: "houses-3",
    title: "Ananta:",
    color: "#034667",
    subtitle: "the infinite.",
    description: "A leader embodies limitless possibilities and energy.",
    quote:
      "Inspiration: Mother Teresa - utmost compassion, extended to the most downtrodden",
  },
  {
    id: "houses-4",
    title: "Akshaya:",
    color: "#CF9832",
    subtitle: "the undiminishing, inexhaustible spirit.",
    description:
      "A leader exemplifies an undiminishing, inexhaustible spirit in all they set out to do.",
    quote:
      "Inspiration: Mahatma Gandhi - inexhaustible strength, tolerance, patience, determination to achieve freedom",
  },
];
export const skillachievementsLsgPalava = [
  {
    imageSource: "/assets/achieve.png", //TODO: graphics to be updated
    name: "International Inter-school competition",
    title: "Kaavya Anuse - Class V",
    title1: "Advika Nair - Class VIII",
  },
  {
    imageSource: "/assets/achieve.png", //same
    name: "Fevicreate: Science Model Making Competition",
    title: "1st Prize: Himadri Melwani - Class IX",
    title1: "2nd Prize: Aryan Shewalkar - Class V",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "CBSE Skill Expo",
    title: "Selected from Pune region,",
    title1: "Chahat Shahdadpuri, Aarav Dorage - Class IX",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "PRO KARATE CHAMPIONSHIP 2023",
    title: "Rank 1 (Gold Medal) - 20 students",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "#1 - BADMINTON DSO LEVEL",
    title: "Mudrika Kaushal - Class VIII (U-17 Category)",
    title1: "Divyansh Khemariya - Class IX (U-17 Category)",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "#2 - CHESS DIVISIONAL LEVEL",
    title: "Sriharsh Shibu Nair - Class VII (U-13 Category)",
    title1: "",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "#2 - 200 MTR RUN DSO LEVEL",
    title: "Kimaya Desai - Class IX (U-17 Category)",
    title1: "",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "#2 - SKATING QUAD DSO LEVEL",
    title: "Kabir Verma - Class V (U-11 Category)",
    title1: "",
  },

  {
    imageSource: "/assets/achieve.png",
    name: "#3 - LAWN TENNIS DSO LEVEL",
    title: "Prisha Suresh Salve - Class VI (U-14 Category)",
    title1: "Satwik Pandey - Class IX (U-17 Category)",
  },
  {
    imageSource: "/assets/achieve.png",
    name: "#3 - ATHLETICS DSO LEVEL",
    title: "Kimaya Desai - Class IX (U-17 Category)",
    title1: "",
  },
];
export const leadershipachievementsLsgPalava = [
  {
    imageSource: "/assets/olympiad1.png",
    name: "World Robot Olympiad",
    title: "1st Prize: Daksh Lashkari & Anishka Gupta - Class XI,",
    title1: "South-West Regional Championship",
  },
  {
    imageSource: "/assets/olympiad2.png",
    name: "World Robot Olympiad",
    title: "Most Innovative Idea Award - National Championship,",
    title1: "Daksh Lashkari & Anishka Gupta - Class XI",
  },
  {
    imageSource: "/assets/olympiad3.png",
    name: "International General Knowledge Olympiad",
    title: "Gold Medal of Excellence - 12 students",
    title1: "",
  },
  {
    imageSource: "/assets/olympiad4.png",
    name: "Medal of Distinction - 7 students",
    title:
      "International Silver Medal - Vidhi Singh & Gaurang Kulkarni (Class II)",
    title1: "International Bronze Medal - Arjun Singh (Class II)",
  },

  {
    imageSource: "/assets/olympiad4.png",
    name: "International Mathematics Olympiad",
    title:
      "Medal of Distinction - Satwik Beri (Class II), Sanchit Dhaifule (Class IV)",
    title1: "Gold Medal of Excellence - 19 students",
  },

  {
    imageSource: "/assets/olympiad4.png",
    name: "International Social Science Olympiad",
    title: "Gold Medal of Excellence - Khanak Sisodiya (Class III),",
    title1: "Arnav Bhatnagar (Class VI), Jyotirmaya Patnaik (Class VIII)",
  },

  {
    imageSource: "/assets/olympiad4.png",
    name: "National Science Olympiad",
    title: "Distinction - Advait Dwivedi, Class II,",
    title1: "Gold Medal of Excellence - 11 students",
  },
];
export const missionLsgPalava = [
  {
    id: "vslide-1",
    text: "To provide quality school education, progressive in challenging pupils to take advantage of the emerging global opportunities.",
  },
  {
    id: "vslide-2",
    text: "To nurture pupils to make them caring, competent and contributing members of society.",
  },
  {
    id: "vslide-3",
    text: "To establish pupil-centred environments for academics as well as extracurricular opportunities that nourish self-efficacy and self-esteem.",
  },
  {
    id: "vslide-4",
    text: "To stimulate critical thinking and commitment to excellence that gradually lead to success in higher education and career.",
  },
  {
    id: "vslide-5",
    text: "To create a school community that reflects and respects ethnic, racial, religious and national diversity.",
  },
];

export const skillLsgPalava = [
  {
    id: "skillLsgPalava-1",
    title: "Sports",
  },
  {
    id: "skillLsgPalava-2",
    title: "Indoor games",
  },
  {
    id: "skillLsgPalava-3",
    title: "Music",
  },
  {
    id: "skillLsgPalava-4",
    title: "Dance",
  },
  {
    id: "skillLsgPalava-5",
    title: "Drama & Theatre",
  },
  {
    id: "skillLsgPalava-6",
    title: "Art",
  },
];

export const leadersLsgPalava = [
  {
    imageSource: "/assets/leaderlsg.png",
    section1: "Captains",
    section2: "Discipline Squad",
    section3: "Event Team",
  },
];

export const captainLsgPalava = [
  {
    name: "Om Patil",
  },
  {
    name: "Abhyaansh Kumar",
  },
  {
    name: "Anubhav Bhattacharjee",
  },
  {
    name: "Saksham Mirje",
  },
  {
    name: "Samruddhi Hiwale",
  },
  {
    name: "Prachi Sharma",
  },
  {
    name: "Oindrila Mukherjee",
  },
  {
    name: "Eidhita Maheswari",
  },
];

export const eventsLsgPalava = [
  {
    name: "Larentina Sanjita",
  },
  {
    name: "Drishti Kanjani",
  },
  {
    name: "Aastha Malvi",
  },
  {
    name: "Shubh Bandekar",
  },
  {
    name: "Shreyash Raut",
  },
];

export const headsLsgPalava = [
  {
    position: "Sports Captain: ",
    name: "Vansh Mukati",
  },
  {
    position: "Sports Vice-Captain: ",
    name: "Ayush Jha",
  },
  {
    position: "Cultural Captain: ",
    name: "Revika Desai",
  },
  {
    position: "Cultural Vice-Captain: ",
    name: "Darsh Shahdapuri",
  },
  {
    position: "Discipline Captain: ",
    name: "Ridhi Shetty",
  },
  {
    position: "Discipline Vice-Captain: ",
    name: "Jyotirmaya Patnaik",
  },
];

export const prefectLsgPalava = [
  {
    position: "Head Boy: ",
    name: "Adwait Rai",
  },
  {
    position: "Head Girl: ",
    name: "Prarthana Desai",
  },
];

export const leadershipLsgPalava = [
  {
    id: "leadershipLsgPalava-1",
    title: "Community Outreach",
  },
  {
    id: "leadershipLsgPalava-2",
    title: "Student Council",
  },
  {
    id: "leadershipLsgPalava-3",
    title: "Competitions",
  },
];

export const schoolLsgPalava = [
  {
    img: "/assets/lsgPalava.jpg",
    name: "LSG, Palava",
    email: "careers_lsg@lodhaworldschool.com",
    phone: "0251-6696312",
    cta: "LSG Palava",
    ctalink: "https://www.lodhaworldschool.com/lsgpalava/",
  },
];

export const applytoLsgPalava = [
  /*{
    link: "#", // TODO: update PDFs - Prabhash
    name: "School Counsellor",
  },
  {
    link: "JD Counselor.pdf",
    name: "Physical Education Teacher",
  },
  {
    link: "JD Special Educator.pdf",
    name: "English Teacher - Primary",
  },
  {
    link: "JD Lab Assistant PCB.pdf",
    name: "Lab Assistant",
  },
  {
    link: "JD Mother Teacher (Grade 1).pdf",
    name: "Chemistry Teacher - Secondary",
  },*/
  {
    link: "JD-Pre-Primary-Teacher.pdf",
    name: "Pre-primary teacher",
  },
  {
    link: "JD Mother Teacher (Grade 1).pdf",
    name: "Chemistry Teacher - Secondary",
  },
];

export const storyLsgPalava = [
  {
    text: "Our school marks the third chapter in the Lodha World School journey, which began in the heart of Thane in 2008. ",
  },
  {
    text: "In June 2019, Lodha World School set up its first CBSE-affiliated campus at Lakeshore Greens, Palava, with the promise of providing education to more than 2500 learners in the neighbourhood.",
  },
  {
    text: "The vision of our school is to nurture the mind, body and spirit of the young learners who walk into our doors. Equipped with well-trained faculty and state-of-the-art infrastructure, our school provides an environment that facilitates the transformation from curious learners to confident leaders of tomorrow.",
  },
  {
    text: "The LSG Community - across its learners, teachers, parents - works together to achieve the LWS motto of creating responsible and empathetic global citizens.",
  },
];

export const markersLsgPalava = [
  {
    id: "symbiotic-1",
  },
  {
    id: "symbiotic-2",
  },
  {
    id: "symbiotic-3",
  },
  {
    id: "symbiotic-4",
  },
  {
    id: "symbiotic-5",
  },
];

export const designLsgPalava = [
  {
    id: "design-1",
  },
  {
    id: "design-2",
  },
  {
    id: "design-3",
  },
  {
    id: "design-4",
  },
];

export const environmentLsgPalava = [
  {
    id: "environment-1",
  },
  {
    id: "environment-2",
  },
  {
    id: "environment-3",
  },
  {
    id: "environment-4",
  },
];

export const wellnessLsgPalava = [
  {
    id: "wellness-1",
  },
  {
    id: "wellness-2",
  },
  {
    id: "wellness-3",
  },
  {
    id: "wellness-4",
  },
];
export const infraLsgPalava = [
  {
    id: "infraLsg-1",
    title: "Classroom",
  },
  {
    id: "infraLsg-2",
    title: "Computer Lab",
  },
  {
    id: "infraLsg-3",
    title: "Library",
  },
  {
    id: "infraLsg-4",
    title: "Art Room",
  },
  {
    id: "infraLsg-5",
    title: "Multipurpose Hall",
  },
  {
    id: "infraLsg-6",
    title: "Sports Room",
  },
  {
    id: "infraLsg-7",
    title: "Dance Lab",
  },
];
export const infraDataLsgPalava = {
  para1:
    "The environment at the school is designed to enable students to excel and flourish - not just in academics, but in activities and events beyond the classroom, too. Lodha World School, LSG is well-equipped with state-of-the-art infrastructure and facilities across all its learning spaces",
};

export const inspireLsgPalava = [
  {
    id: "inspireUsThane-1",
    title: "Learner-centric",
    description:
      "Following the Montessori Methodology, our curriculum presupposes that each learner has a unique individual promise and unique abilities, thus encouraging personalised learning.",
  },
  {
    id: "inspireUsThane-2",
    title: "Inclusive",
    description:
      "The instructional approach and content, learning objectives, and pace of learning are optimised according to each learner’s needs, ensuring no learner is left behind.",
  },
  {
    id: "inspireUsThane-3",
    title: "Holistic",
    description:
      "Learning activities are meaningful and relevant to both the academic and personal lives of learners, making learning holistic.",
  },
  {
    id: "inspireUsThane-4",
    title: "Skill-based",
    description:
      "The curriculum is designed to build artistic creativity, design thinking, and innovation. It is also deeply entrenched in our Four Markers, which helps train and empower our learners.",
  },
];
