import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Box,
  IconButton,
  Button,
  Toolbar,
  Typography,
  Dialog,
  Grid,
  List,
  Collapse,
  Divider,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import Slide from "@mui/material/Slide";
import usePathParameter from "../utils/usePathParameter";
import { openFileInNewTab } from "../utils/minio";
import { sidebarMain } from "../data/MainSiteData";
import { sidebarCbgPalava } from "../data/CbgPalavaIndividualSiteData";
import { sidebarLsgPalava } from "../data/LsgPalavaIndividualSiteData";
import { sidebarTaloja } from "../data/TalojaIndividualSiteData";
import { sidebarThane } from "../data/ThaneIndividualSiteData";

const useStyles = makeStyles((theme) => ({
  mainCta: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mainCtaDiv: {
    margin: "1reM 1rem 2rem 1rem",
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  secDiv: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  CloseButton: {
    borderRadius: "50px",
    border: "1px solid #263657",
    "&:hover": {
      backgroundColor: "#263657",
      border: "1px solid #263657",
      color: "#E3D5CA",
    },
  },
  MenuButton: {
    backgroundColor: "#E3D5CA",
    border: "1px solid #E3D5CA",
    "&:visited": {
      backgroundColor: "#263657",
      border: "1px solid #263657",
      color: "#E3D5CA",
    },
  },
  fourSchoolImg: {
    maxWidth: "100%",
    cursor: "pointer",
    // height: '100%',
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  link: {
    textDecoration: "none",
    color: "#263657",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: "16px",
  },
  highlight: {
    background: "#263657",
    padding: "6px",
    textDecoration: "none",
    color: "#fff",
    "&:hover": {
      textDecoration: "underline",
    },
    fontSize: "16px",
  },
  menuTitle: {
    fontSize: "32px",
    marginTop: 0,
  },
  navSections: {
    display: "flex",
    // justifyContent: 'center',
    [theme.breakpoints.down("sm")]: {
      display: "initial",
    },
  },
  diagContent: {
    padding: "16px 60px",
    [theme.breakpoints.down("sm")]: {
      padding: "0 16px",
    },
  },
  brochureDiv: {
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  subMenu: {
    margin: "40px",
    [theme.breakpoints.down("md")]: {
      margin: "45px 16px",
    },
  },
  subMenu1: {
    margin: "40px 66px 40px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "45px 16px",
    },
  },
  containermain: {
    margin: "70px auto",
    alignItems: "initial",
    [theme.breakpoints.down("md")]: {
      margin: 0,
      flexDirection: "column-reverse",
    },
  },
  joinLwsText: {
    paddingTop: "1.7rem",
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
    },
  },
  logo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "auto",
  },
  media: {
    display: "flex",
    margin: "0.5rem 1rem",
    justifyContent: "space-between",
  },
  divider: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  ctaLink: {
    textDecoration: "none",
  },
  flipbook: {
    width: 500,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NavMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openList, setOpenList] = useState(false);
  const { isIndividual, isTaloja, isLsgPalava, isCbgPalava, isThane, pathUrl } =
    usePathParameter();
  const [sidebar, setSidebar] = useState([]);
  const handleListClick = () => {
    setOpenList(!openList);
  };

  const openBrochure = () => {
    openFileInNewTab("brochure.pdf");
  };

  const openPublicDisclosure = () => {
    openFileInNewTab("Public Disclosure.pdf");
  };

  function handleClick(event) {
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  }

  function handleClose() {
    setAnchorEl(null);
  }

  React.useEffect(() => {
    if (isThane) {
      setSidebar(sidebarThane);
    } else if (isCbgPalava) {
      setSidebar(sidebarCbgPalava);
    } else if (isLsgPalava) {
      setSidebar(sidebarLsgPalava);
    } else if (isTaloja) {
      setSidebar(sidebarTaloja);
    } else if (!isIndividual) {
      setSidebar(sidebarMain);
    }
  }, [isCbgPalava, isIndividual, isLsgPalava, isTaloja, isThane]);

  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="primary"
        variant="contained"
        aria-label="menu"
        sx={{ mr: 2 }}
        aria-owns={anchorEl ? "simple-menu" : undefined}
        onClick={handleClick}
        onMouseOver={handleClick}
        className={classes.MenuButton}
      >
        <MenuRoundedIcon />
      </IconButton>

      <Dialog
        fullScreen
        id="simple-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: "#E3D5CA",
          },
        }}
      >
        <DialogTitle>
          <AppBar position="static" elevation={0} sx={{ bgcolor: "#E3D5CA" }}>
            <Toolbar style={{ padding: 0 }}>
              <Box sx={{ flexGrow: 1 }}></Box>
              <Box className={classes.mainCta}>
              {/*<a
                  href={
                    isIndividual
                      ? `${pathUrl}/university-fair`
                      : "/university-fair"
                  }
                  rel="noreferrer"
                  className={classes.ctaLink}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{ mr: 2 }}
                    onClick={() => {}}
                  >
                    LWS University Fair
                  </Button>
                </a> */}
                <a
                  href={`https://applyonline.lodhaworldschool.com${
                    localStorage.getItem("queryString") ?? ""
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.ctaLink}
                >
                  <Button
                    color="primary"
                    variant="outlined"
                    sx={{ mr: 5 }}
                    onClick={() => {}}
                  >
                    Admissions AY 2025-26
                  </Button>
                </a>
              </Box>
              <IconButton
                edge="start"
                onClick={handleClose}
                aria-label="close"
                size="large"
                color="secondary"
                variant="contained"
                sx={{ mr: 2, bgcolor: "#263657" }}
                className={classes.CloseButton}
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent className={classes.diagContent}>
          <Grid container className={classes.containermain}>
            <Grid item xs={12} md={3}>
            <Box className={classes.subMenu}>
                    <a href={isIndividual ? `${pathUrl}` : "/"}>
                      <img
                        src="/assets/logo.svg"
                        alt="go to home"
                        width="120px"
                        class="responsiveImg"
                        className={classes.logo}
                      />
                    </a>
                    <Typography
                      style={{
                        margin: "1rem",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                    >
                      <b>Connect with LWS</b>
                    </Typography>
                    <Box className={classes.media}>
                      <a
                        href={sidebar.fb}
                        alt="fb"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/fb.svg" alt="" />
                      </a>
                      <a
                        href={sidebar.insta}
                        alt="insta"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/insta.svg" alt="" />
                      </a>
                      <a
                        href={sidebar.linkedin}
                        alt="linkedin"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src="/assets/linkedin.svg" alt="" />
                      </a>
                    </Box>
                  </Box>
              
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              style={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-around",
              }}
            >
              <Box>
                <Box className={classes.navSections}>
                  <Box className={classes.subMenu} style={{ marginTop: 0 }}>
                    <Typography
                      class="h2"
                      color="primary"
                      variant="h1"
                      gutterBottom
                      style={{ marginTop: 0 }}
                    >
                      About Us
                    </Typography>
                    <Box
                      sx={{ color: (theme) => theme.palette.black.main }}
                      style={{ lineHeight: "27px" }}
                    >
                      <Box>
                        <Link
                          to={
                            isIndividual ? `${pathUrl}/ourstory` : "/ourstory"
                          }
                          className={classes.link}
                        >
                          Our Story
                        </Link>{" "}
                      </Box>
                      <div
                        style={{
                          display: "inline-flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={handleListClick}
                      >
                        <Box>
                          <Link to="" className={classes.link}>
                            Our Philosophy
                          </Link>{" "}
                        </Box>
                        {openList ? <ExpandLess /> : <ExpandMore />}
                      </div>

                      <Collapse in={openList} timeout="auto" unmountOnExit>
                        <List component="div" sx={{ display: "grid", ml: 1 }}>
                          <Box>
                            <Link
                              to={
                                isIndividual ? `${pathUrl}/vision` : "/vision"
                              }
                              className={classes.link}
                            >
                              {" "}
                              &#8226; Vision
                            </Link>{" "}
                          </Box>
                          <Box>
                            <Link
                              to={
                                isIndividual
                                  ? `${pathUrl}/lwsmarkers`
                                  : "/lwsmarkers"
                              }
                              className={classes.link}
                            >
                              {" "}
                              &#8226; LWS Markers
                            </Link>{" "}
                          </Box>
                          <Box>
                            <Link
                              to={
                                isIndividual ? `${pathUrl}/values` : "/values"
                              }
                              className={classes.link}
                            >
                              {" "}
                              &#8226; Values
                            </Link>{" "}
                          </Box>
                        </List>
                      </Collapse>
                      <Box>
                        <Link
                          to={isIndividual ? `${pathUrl}/people` : "/people"}
                          className={classes.link}
                        >
                          People
                        </Link>{" "}
                      </Box>
                      {isLsgPalava ? (
                        <Box>
                          <Link
                            to="#"
                            onClick={openPublicDisclosure}
                            className={classes.link}
                          >
                            Public Disclosure
                          </Link>{" "}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Box className={classes.subMenu} style={{ marginTop: 0 }}>
                    <Typography
                      class="h2"
                      color="primary"
                      variant="h1"
                      gutterBottom
                      style={{ marginTop: 0 }}
                    >
                      Academics
                    </Typography>
                    <Box
                      sx={{ color: (theme) => theme.palette.black.main }}
                      style={{ lineHeight: "27px" }}
                    >
                      {isIndividual ? null : (
                        <Box>
                          <Link
                            to={
                              isIndividual
                                ? `${pathUrl}/inspireuscurriculum`
                                : "/inspireuscurriculum"
                            }
                            className={classes.link}
                          >
                            The Inspire Us Curriculum
                          </Link>{" "}
                        </Box>
                      )}

                      {/* {isIndividual ? null : (
                        <Box>
                          <Link
                            to={
                              isIndividual
                                ? `${pathUrl}/cbsetennistournament`
                                : "/cbsetennistournament"
                            }
                            className={classes.link}
                          >
                            CBSE Tennis Tournament
                          </Link>{" "}
                        </Box>
                      )} */}

                      {isIndividual ? (
                        <Box>
                          <Link
                            to={
                              isIndividual
                                ? `${pathUrl}/inspireuscurriculum`
                                : "/inspireuscurriculum"
                            }
                            className={classes.link}
                          >
                            Foundation and Middle Years
                          </Link>{" "}
                        </Box>
                      ) : null}

                      {isIndividual ? (
                        <Box>
                          <Link
                            to={
                              isIndividual
                                ? `${pathUrl}/senioryears`
                                : "/senioryears"
                            }
                            className={classes.link}
                          >
                            Senior Years
                          </Link>{" "}
                        </Box>
                      ) : null}

                      {!isTaloja ? (
                        <Box>
                          <Link
                            to={
                              isIndividual ? `${pathUrl}/results` : "/results"
                            }
                            className={classes.link}
                          >
                            Results &amp; Placements
                          </Link>{" "}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                  <Box className={classes.subMenu} style={{ marginTop: 0 }}>
                    <Typography
                      class="h2"
                      color="primary"
                      variant="h1"
                      gutterBottom
                      style={{ marginTop: 0 }}
                    >
                      Beyond the Classroom
                    </Typography>
                    <Box
                      sx={{ color: (theme) => theme.palette.black.main }}
                      style={{ lineHeight: "27px" }}
                    >
                      <Box>
                        <Link
                          to={
                            isIndividual
                              ? `${pathUrl}/skilldevelopment`
                              : "/skilldevelopment"
                          }
                          className={classes.link}
                        >
                          Skill Development
                        </Link>{" "}
                      </Box>
                      <Box>
                        <Link
                          to={
                            isIndividual
                              ? `${pathUrl}/leadershipdevelopment`
                              : "/leadershipdevelopment"
                          }
                          className={classes.link}
                        >
                          Leadership Development
                        </Link>{" "}
                      </Box>
                      {isIndividual ? (
                        <Box>
                          <Link
                            to={
                              isIndividual
                                ? `${pathUrl}/infrastructure`
                                : "/infrastructure"
                            }
                            className={classes.link}
                          >
                            Infrastructure
                          </Link>{" "}
                        </Box>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                <Divider className={classes.divider} />
                <Box className={classes.navSections}>
                  <Box className={classes.subMenu}>
                    <Typography
                      class="h2"
                      color="primary"
                      variant="h1"
                      gutterBottom
                    >
                      In Other’s <br />
                      Thoughts
                    </Typography>
                    <Box
                      sx={{ color: (theme) => theme.palette.black.main }}
                      style={{ lineHeight: "27px" }}
                    >
                      <Box>
                        <Link
                          to={isIndividual ? `${pathUrl}/awards` : "/awards"}
                          className={classes.link}
                        >
                          Awards
                        </Link>
                      </Box>
                      <Box>
                        <Link
                          to={
                            isIndividual
                              ? `${pathUrl}/testimonials`
                              : "/testimonials"
                          }
                          className={classes.link}
                        >
                          Testimonials
                        </Link>
                      </Box>
                      
                    </Box>
                  </Box>
                  <Box className={classes.subMenu1}>
                    <Typography
                      class="h2"
                      color="primary"
                      variant="h1"
                      gutterBottom
                    >
                      Join LWS
                    </Typography>
                    <Box
                      sx={{ color: (theme) => theme.palette.black.main }}
                      style={{ lineHeight: "27px" }}
                      className={classes.joinLwsText}
                    >
                      <Box>
                        <Link
                          to={isIndividual ? `${pathUrl}/career` : "/career"}
                          className={classes.link}
                        >
                          Career
                        </Link>{" "}
                      </Box>
                      <Box>
                        <Link
                          to={isIndividual ? `${pathUrl}/alumni` : "/alumni"}
                          className={classes.link}
                        >
                          Alumni
                        </Link>{" "}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.subMenu}>
                    <Typography
                      class="h2"
                      color="primary"
                      variant="h1"
                      gutterBottom
                    >
                      LWS Bulletin <br /> Board
                    </Typography>
                    <Box
                      sx={{ color: (theme) => theme.palette.black.main }}
                      style={{ lineHeight: "27px" }}
                    >
                      <Box>
                        <Link
                          to={
                            isIndividual
                              ? `${pathUrl}/newsletter`
                              : "/newsletter"
                          }
                          className={classes.link}
                        >
                          Newsletter
                        </Link>
                      </Box>
                      {/* <Box>
                        <Link
                          to={isIndividual ? `${pathUrl}/awards` : "/awards"}
                          className={classes.link}
                        >
                          Blog
                        </Link>
                      </Box> */}
                      <Box className={classes.brochureDiv}>
                <Typography
                  class="h3"
                  color="primary"
                  variant="h1"
                  gutterBottom
                  style={{
                    lineHeight: "30px",
                    margin: "0px",
                    padding: "0px",
                    cursor: "pointer",
					fontWeight: "normal",
					textAlign: "left",
          fontSize: "16px",
                  }}
                  onClick={openBrochure}
                >
                  Brochure
                </Typography>
              </Box>
                      
                    </Box>
                  </Box>
                  
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default NavMenu;
