export const pageSettingsMain = {
  landingPage: {
    title: "Lodha World School - Chain Of Quality Schools In Mumbai & Thane",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School delivers academic excellence across ICSE, CBSE, SSC & ISC curricula, providing world-class education to young learners across Mumbai. Be a part of one of the best schools in Mumbai.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - Chain Of Quality Schools In Mumbai & Thane",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      { property: "og:url", content: "https://www.lodhaworldschool.com/" },
      {
        property: "og:description",
        content:
          "Lodha World School delivers academic excellence across ICSE, CBSE, SSC & ISC curricula, providing world-class education to young learners across Mumbai. Be a part of one of the best schools in Mumbai.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/",
  },
  ourStory: {
    title: "Explore Lodha World School's Remarkable Journey since 2008",
    metaTags: [
      {
        name: "description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      {
        property: "og:title",
        content: "Explore Lodha World School's Remarkable Journey since 2008",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      { property: "og:url", content: "https://www.lodhaworldschool.com/" },
      {
        property: "og:description",
        content:
          "Join us in celebrating our incredible journey since 2008 where Lodha World School has flourished into a vibrant community with 4 campuses & affiliations with ICSE, CBSE, SSC, and ISC boards.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/chairperson.jpg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/ourstory",
  },
  vision: {
    title: "Lodha World School - Vision & Mission",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us!",
      },
      {
        property: "og:title",
        content: "Lodha World School - Vision & Mission",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/inspireuscurriculum",
      },
      {
        property: "og:description",
        content:
          "Discover the vision and mission of Lodha World School (LWS), dedicated to nurturing individuals in body, mind, and spirit, and empowering them to become the Leaders of Tomorrow. Join Us! ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/vision",
  },
  values: {
    title:
      "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
    metaTags: [
      {
        name: "description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      {
        property: "og:title",
        content:
          "Lodha World School Core Values: Supporting Leaders with Eternal Virtues",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/values",
      },
      {
        property: "og:description",
        content:
          "Discover the core values at Lodha World School and how they are instilled through our four houses, each rooted in Sanskrit with timeless significance. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/values",
  },
  people: {
    title: "Meet the Leaders Behind Lodha World School's Vision",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      {
        property: "og:title",
        content: "Meet the Leaders Behind Lodha World School's Vision",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/people",
      },
      {
        property: "og:description",
        content:
          "Explore the dedicated individuals shaping the future of Lodha World School. From our Chairperson to esteemed members of the School Managing Committee & principals across our campuses.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/people",
  },
  inspireUsCurriculum: {
    title: "Lodha World School Custom Curriculum - Inspire Us",
    metaTags: [
      {
        name: "description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum is specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      {
        property: "og:title",
        content: "Lodha World School Custom Curriculum - Inspire Us",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/vision",
      },
      {
        property: "og:description",
        content:
          "Discover 'Inspire Us' by Lodha World School, a globally researched custom curriculum is specially designed to deliver the academic rigour and excellence we aspire to achieve.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/inspireuscurriculum",
  },
  cbsetennistournament: {
    title: "Lodha World School CBSE Tennis Tournament",
    metaTags: [
      {
        name: "description",
        content:
          "CBSE Tennis Tournament",
      },
      {
        property: "og:title",
        content: "Lodha World School CBSE Tennis Tournament",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/vision",
      },
      {
        property: "og:description",
        content:
          "CBSE Tennis Tournament",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/cbsetennistournament",
  },
  results: {
    title: "Lodha World Schools - Results",
    metaTags: [
      {
        name: "description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      {
        property: "og:title",
        content: "Lodha World Schools - Results",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/results",
      },
      {
        property: "og:description",
        content:
          "Explore the results of the schools of Lodha World School. Witness the fruits of collaborative efforts among our leaders, staff, parents, and students, as we share the remarkable journeys.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/results",
  },
  skillDevelopment: {
    title: "Lodha World School - Skill Development Beyond the Classroom",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Skill Development Beyond the Classroom",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/skilldevelopent",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, education goes beyond classroom boundaries. Discover how Skill Development play a pivotal role in nurturing all-round leaders. ",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/skilldevelopment",
  },
  leadershipDevelopment: {
    title:
      "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      {
        property: "og:title",
        content:
          "Lodha World Schools - Leadership Development | Empowering Tomorrow's Leaders",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/leadershipdevelopment",
      },
      {
        property: "og:description",
        content:
          "At LWS, education extends beyond traditional classrooms. We aim to mould & nurture all-round leaders, facilitating their development through our range of co-curricular activities & opportunities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/leadershipdevelopment",
  },
  awards: {
    title: "Lodha World School - Awards and Achievements",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Awards and Achievements",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/awards",
      },
      {
        property: "og:description",
        content:
          "Lodha World School garnered recognition and awards that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/awards",
  },
  newsletter: {
    title: "Lodha World School - newsletter and Achievements",
    metaTags: [
      {
        name: "description",
        content:
          "Lodha World School garnered recognition and newsletter that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      {
        property: "og:title",
        content: "Lodha World School - newsletter and Achievements",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/newsletter",
      },
      {
        property: "og:description",
        content:
          "Lodha World School garnered recognition and newsletter that set them apart in the field of education. From prestigious Grand Jury Awards to Reliance Awards, explore their outstanding achievements.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/newsletter",
  },
  
  testimonials: {
    title: "Lodha World School - Testimonials From Parents & Students",
    metaTags: [
      {
        name: "description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Testimonials From Parents & Students",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/testimonials",
      },
      {
        property: "og:description",
        content:
          "Discover what parents & students has to say about the school's dedication to fostering well-rounded personalities through a balanced focus on academics and extracurricular activities.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/testimonials",
  },
  staff: {
    title:
      "Lodha World School - Career Opportunities & Benefits | Join Our Team",
    metaTags: [
      {
        name: "description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      {
        property: "og:title",
        content:
          "Lodha World School - Career Opportunities & Benefits | Join Our Team",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/career",
      },
      {
        property: "og:description",
        content:
          "At LWS, our staff members are lifelong learners, equipped with professional credentials and additional training from renowned institutions. Check LWS career opportunities here.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/career",
  },
  alumni: {
    title: "Lodha World School - Alumni: Forever Part Of Our Community",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Alumni: Forever Part Of Our Community",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/alumni",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, our alumni are a source of immense pride. Witness the transformation of our former students into the leaders they were destined to become.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/alumni",
  },
  lwsMarkers: {
    title: "Lodha World School - Markers",
    metaTags: [
      {
        name: "description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      {
        property: "og:title",
        content: "Lodha World School - Markers",
      },
      { property: "og:site_name", content: "lodhaworldschoolmumbai" },
      {
        property: "og:url",
        content: "https://www.lodhaworldschool.com/lwsmarkers",
      },
      {
        property: "og:description",
        content:
          "At Lodha World School, the pupil is at the heart of everything we do. We go the extra mile to mould these young leaders in revolutionary ways.",
      },
      { property: "og:type", content: "website" },
      {
        property: "og:image",
        content: "https://www.lodhaworldschool.com/assets/logo.svg",
      },
    ],
    canonical: "https://www.lodhaworldschool.com/lwsmarkers",
  },
};

export const sidebarMain = {
  fb: "https://www.facebook.com/lodhaworldschoolmumbai",
  linkedin: "https://www.linkedin.com/company/lodha-world-school/",
  insta: "https://instagram.com/lodhaworldschool?igshid=YmMyMTA2M2Y=",
  email: "mailto:info@lodhaworldschool.com",
  admission: "mailto:admissions@lodhaworldschool.com",
  emailname: "info@lodhaworldschool.com",
  admissionname: "admissions@lodhaworldschool.com",
};
export const sidebarlinkMain = [
  {
    logo: "/assets/board1.png",
    link: "https://www.cbse.gov.in/",
    width: "80px",
  },
  {
    logo: "/assets/board2.png",
    link: "https://cisce-gov.in/",
    width: "80px",
  },
  {
    logo: "/assets/board3.png",
    link: "https://www.mahahsscboard.in/",
    width: "80px",
  },
];

export const websiteSchemaMain = {
  "@context": "https://schema.org",
  "@type": "Organization",
  name: "Lodha World School",
  url: "https://www.lodhaworldschool.com/",
  logo: "https://www.lodhaworldschool.com/assets/logo.svg",
};
export const personSchemaMain = {
  "@context": "http://schema.org",
  "@type": "WebPage",
  accountablePerson: [
    {
      "@type": "Person",
      name: "Mrs. Manju Mangalprabhat Lodha",
      url: "https://www.lodhaworldschool.com/people",
      image: "https://www.lodhaworldschool.com/assets/people1.jpg",
      jobTitle: "Chairperson",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Raunika Malhotra",
      url: "https://www.lodhaworldschool.com/people",
      image: "https://www.lodhaworldschool.com/assets/people2.jpg",
      jobTitle: "President, Marketing, Lodha Group",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Anushree Gandhi",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people3.jpg",
      jobTitle: "AVP - Education, Lodha Group",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Asha Narayan",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people5.jpg",
      jobTitle: "Head of Curriculum",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Indrani Banerjee",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people4.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Aditi Banerji",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people8.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
    {
      "@type": "Person",
      name: "Dr. Rashmirekha Saha",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people6.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
    {
      "@type": "Person",
      name: "Ms. Ajita Gupta",
      url: "",
      image: "https://www.lodhaworldschool.com/assets/people7.jpg",
      jobTitle: "Principal",
      worksFor: {
        "@type": "Organization",
        name: "Lodha World School",
      },
    },
  ],
};

export const lightBgWebMain = {
  imageSource: "/assets/bg8.gif",
};

export const lightBgMobileMain = {
  imageSource: "/assets/bg8mobile.gif",
};

export const landingMain = {
  imageSource: "",
  title1: "Lodha World School - ",
  title2: "Where excellence is a way of life",
};

export const inspireUsMain = {
  title1: "The Inspire Us",
  title2: "Curriculum",
  alt: "Lodha World School - Inspire Us Curriculum",
  imgTitle: "Lodha World School has the Inspire Us Curriculum",
};



export const homeAwardsMain = {
  imageSource: "/assets/starawards.png",
  id: "awardsMain",
};

export const starAwardsMain = [
  {
    imageSource: "/assets/awards.png",
    name: "#9, Technology Avant Garde, The Grand Jury Awards – 2019",
    name1: "Awarded to LWS Thane among 1000 nominations across India",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Reliance Foundation Teacher Awards – 2019",
    name1: "Awarded to Principal Ms. Asha Narayanan, LWS Thane",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Top 20 in Education World India Rankings 2019-20",
    name1: "Awarded to CBG Palava - #14 across Mumbai and #18 across Maharashtra",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Outstanding Initiative of the Year – Teacherathon 2019",
    name1: "Awarded to LWS Thane by Lilavatibai Podar",
  },
  {
    imageSource: "/assets/awards.png",
    name: "International School Award",
    name1: "Awarded to CBG Palava by the British Council",
  },
];

export const homeNewsletterMain = {
  imageSource: "/assets/starawards.png",
  id: "newsletterMain",
};
export const starNewsletterMain = [
  {
    imageSource: "/assets/8.8.2024_LWS-Newsletter-Thumbnail-500x500px.jpg",
    name: "Issue 01",
    name1: "June 2024",
    link: "/assets/01-June-24.pdf",
  },
  {
    imageSource: "",
    name: " ",
    name1: " ",
  },
];

export const academicsMain = {
  imageSource: "/assets/academicsImg.png",
  title: "Class of 2024 Results",
  name: "Our commitment to academic excellence exemplified",
  cta: "Class of 2024 Results",
  alt: "Lodha World School - Commitment to Academics",
  imgTitle: "Lodha World School has Commitment to Academics",
};

export const joinLwsMain = {
  title: "Pursue excellence at LWS",
  imageSource: "/assets/students.jpg",
};

export const missionMain = [
  {
    id: "vslide-1",
    text: "To provide quality school education, progressive in challenging pupils to take advantage of the emerging global opportunities.",
  },
  {
    id: "vslide-2",
    text: "To nurture pupils to make them caring, competent and contributing members of society.",
  },
  {
    id: "vslide-3",
    text: "To establish pupil-centred environments for academics as well as extracurricular opportunities that nourish self-efficacy and self-esteem.",
  },
  {
    id: "vslide-4",
    text: "To stimulate critical thinking and commitment to excellence that gradually lead to success in higher education and career.",
  },
  {
    id: "vslide-5",
    text: "To create a school community that reflects and respects ethnic, racial, religious and national diversity.",
  },
];
export const cbsetennistournamentImgMain = {
  alt: "Cbse Tennis Tournament",
  imgTitle: "Cbse Tennis Tournament",
};
export const cbsetennistournamentMain = [
  {
    id: "houses-1",
    title: "Atulya:",
    color: "#9C2020",
    subtitle: "the incomparable.",
    description:
      "A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
    quote:
      "Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives",
  },
];

export const valuesImgMain = {
  alt: "Lodha World School - Values",
  imgTitle: "Values of Lodha World School",
};
export const valuesMain = [
  {
    id: "houses-1",
    title: "Atulya:",
    color: "#9C2020",
    subtitle: "the incomparable.",
    description:
      "A leader is incomparable, unequalled in their quest for excellence, and exhibits unmitigable zeal.",
    quote:
      "Inspiration: Shri JRD Tata - ethical and trailblazing business leadership & corporate social initiatives",
  },
  {
    id: "houses-2",
    title: "Advaita:",
    color: "#276440",
    subtitle: "the one and only one.",
    description: "A leader has an unchanging, singular vision of the goal.",
    quote:
      "Inspiration: Shri APJ Abdul Kalam - unique peerless vision for our country and society",
  },
  {
    id: "houses-3",
    title: "Ananta:",
    color: "#034667",
    subtitle: "the infinite.",
    description: "A leader embodies limitless possibilities and energy.",
    quote:
      "Inspiration: Mother Teresa - utmost compassion, extended to the most downtrodden",
  },
  {
    id: "houses-4",
    title: "Akshaya:",
    color: "#CF9832",
    subtitle: "the undiminishing, inexhaustible spirit.",
    description:
      "A leader exemplifies an undiminishing, inexhaustible spirit in all they set out to do.",
    quote:
      "Inspiration: Mahatma Gandhi - inexhaustible strength, tolerance, patience, determination to achieve freedom",
  },
];

export const peopleMain = [
  {
    imageSource: "/assets/people1.jpg",
    imageAlt: "Mrs. Manju Mangalprabhat Lodha - Chairperson, LWS",
    imgTitle: "Mrs. Manju Mangalprabhat Lodha is the Chairperson of LWS",
    name: "Mrs. Manju Mangalprabhat Lodha",
    title: "Chairperson",
  },
  {
    imageSource: "/assets/people2.jpg",
    imageAlt: "Ms Raunika Malhotra - Member, School Managing Committee, LWS",
    imgTitle:
      "Ms Raunika Malhotra is a Member of School Managing Committee at LWS",
    name: "Ms. Raunika Malhotra - Member, School Managing Committee",
    title: "President - Marketing, Lodha Group",
  },
  {
    imageSource: "/assets/people3.jpg",
    imageAlt: "Ms. Anushree Gandhi - Member, School Managing Committee, LWS",
    imgTitle:
      "Ms. Anushree Gandhi is a Member of School Managing Committee at LWS",
    name: "Ms. Anushree Gandhi - Member, School Managing Committee",
    title: "AVP - Education, Lodha Group",
  },
  {
    imageSource: "/assets/people8.jpg",
    imageAlt: "Ms. Aditi Banerji - Head of Committee, LWS",
    imgTitle: "Ms. Aditi Banerji is the Head of Committee at LWS",
    name: "Ms. Aditi Banerji",
    title: "Dean – Lodha World School",
  },
  {
    imageSource: "/assets/people4.jpg",
    imageAlt: "Ms. Indrani Banerjee - Principal, LWS Thane",
    imgTitle: "Ms. Indrani Banerjee is a Principal at LWS Thane",
    name: "Ms. Indrani Banerjee",
    title: "Principal, LWS Thane",
  },
  {
    imageSource: "/assets/people11.png",
    imageAlt: "Ms. Priya Venkatesh - Principal, LWS CBG Palava",
    imgTitle: "Ms. Priya Venkatesh is a Principal at LWS CBG Palava",
    name: "Ms. Priya Venkatesh",
    title: "Principal, LWS CBG Palava",
  },
  {
    imageSource: "/assets/people6.jpg",
    imageAlt: "Dr. Rashmireknha Saha - Principal, LWS LSG Palava",
    imgTitle: "Dr. Rashmirekha Saha is a Principal at LWS LSG Palava",
    name: "Dr. Rashmirekha Saha",
    title: "Principal, LWS LSG Palava",
  },
  {
    imageSource: "/assets/people7.jpg",
    imageAlt: "Ms. Ajita Gupta - Principal, LWS Taloja",
    imgTitle: "Ms. Ajita Gupta is a Principal at LWS Taloja",
    name: "Ms. Ajita Gupta",
    title: "Principal, LWS Taloja",
  },
];

export const testimonialsMain = [
  {
    name1: "Dipti Swapnil Deshpande",
    name2: "Parent of Harsh Deshpande",
    title:
      "We are thankful to Lodha World School for nurturing kids to build 'Leaders of Tomorrow'. Their focus on exposing students to both academic and extracurricular activities like Dramatics, Interschool Competitions etc. makes them well-rounded personalities.",
  },
  {
    name1: "Dinesh Kumar and Dinesh Kumar and Deepti Mehendiratta",
    name2: "Parents of Dhruv and Diya Mehendiratta",
    title:
      "Lodha World School has helped our children achieve their dreams. Constant guidance and encouragement provided by teachers at every step played a major role in developing their personality and infused them with courage and confidence to face the world.",
  },
  {
    name1: "Arunima Raghunath",
    name2: "parent of Anandita Vashisht",
    title:
      "Lodha World School offers an engaging and interactive learning environment, which helps my child think out of the box and practically apply the learnt concepts and theory. The co-curricular and extracurricular activities help develop her personality. Also, excellent teachers and facilities. As a parent, I am very happy and grateful.",
  },
  {
    name1: "Bhagyashri Sisodia",
    name2: "LWS Thane, Batch of 2020",
    title:
      "The valuable guidance of such wonderful teachers, thought-provoking class projects, educational trips, and even my tenure as House Captain favourably transformed me as a person, making me more adaptable and empathetic. I am deeply grateful to the LWST family for their vital support and cherishable memories to look back on",
  },
  {
    name1: "Chitrakshi Bhadkamkar",
    name2: "LWS Thane, Batch of 2019",
    title:
      "The knowledge and variety of curricular and extracurricular activities available to me in school encouraged me to discover my potential.",
  },
];

export const resultsMain = [
  {
    id: "piechart-1",
    title: "ISC Class of 2022 - CBG Palava",
  },
  {
    id: "piechart-3",
    title: "ICSE Class of 2023 - Thane",
  },
  {
    id: "piechart-2",
    title: "ICSE Class of 2023 - CBG Palava",
  },
];

export const eventsMain = [];

export const awardsMain = [
  {
    imageSource: "/assets/awards2.png",
    name: "#2 in Kalyan, Education World India Rankings 2022-23",
  },
  {
    imageSource: "/assets/awards1.png",
    name: "#4 in Thane, Education World India Rankings 2022-23",
  },
  {
    imageSource: "/assets/awards3.png",
    name: "#17 in Mumbai, Times School Survey Rankings 2022",
  },
  {
    imageSource: "/assets/awards9.png",
    name: "#9, Technology Avant Garde, The Grand Jury Awards – 2019",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Reliance Foundation Teacher Awards – 2019",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Top 20 in Education World India Rankings 2019-20",
  },
  {
    imageSource: "/assets/awards.png",
    name: "Outstanding Initiative of the Year – Teacherathon 2019",
  },
  {
    imageSource: "/assets/awards.png",
    name: "International School Award",
  },
];

export const skillachievementsMain = [];
export const leadershipachievementsMain = [];
export const leadersMain = [];
export const prefectMain = [];
export const captainMain = [];
export const headsMain = [];
export const seniorYearsMain = {};
export const seniorpara1Main = {};

export const seniorpara2Main = [];

export const seniorpara3Main = [];

export const seniorpara4Main = [];

export const chairpersonMsgMain = {
  imageSource: "/assets/chairperson.jpg",
  name: "Mrs. Manju M. Lodha",
  alt: "Mrs. Manju Mangalprabhat Lodha, Chairperson, Lodha World School",
  imgTitle: "Mrs. Manju Mangalprabhat Lodha is the Chairperson of LWS",
  title:
    "We welcome your child to explore and enjoy the journey to an excellent educational future ahead!",
  nameMain: "-Mrs. Manju Mangalprabhat Lodha",
  cta: "Chairperson's Message",
  dialogText1:
    "We have all been students once – the long hours of exam preparation, sports day, inter-house competitions and more. A good education gives a child wings to fly and fulfil their dreams, ensuring a brighter future. It is a pleasure to have the opportunity to give back what we learnt from mentors and teachers and recreate the experience for the learners of today.",
  dialogText2:
    "We believe that the early formative years are critical for a child to grow up as a bright and mature individual and a responsible leader of tomorrow.",
  dialogText3:
    "Lodha World School provides the right atmosphere to help develop an inquisitive mind for creative intelligence. The school syllabus is agnostic to boards and defines a base reference in terms of content-based learning. To ensure ‘Academic Rigour’, it is two notches higher than the NCERT/Board council defined syllabus and focuses on delivering content that maximises academic proficiency.",
  dialogText4:
    "On behalf of the administration and faculty, I am delighted to introduce your child to a world of opportunities. As a mother myself, I assure you that Lodha World School is the right decision for your child. I invite you to visit the school and experience its facilities. We welcome your child to explore and enjoy this journey to an excellent educational future ahead!",
};

export const inspireMain = [
  {
    id: "inspireUs-1",
    title: "Learner-centric",
    description:
      "Following the Montessori Methodology, our curriculum presupposes that each learner has a unique individual promise and unique abilities, thus encouraging personalised learning.",
  },
  {
    id: "inspireUs-2",
    title: "Inclusive",
    description:
      "The instructional approach and content, learning objectives, and pace of learning are optimised according to each learner’s needs, ensuring no learner is left behind.",
  },
  {
    id: "inspireUs-3",
    title: "Holistic",
    description:
      "Learning activities are meaningful and relevant to both the academic and personal lives of learners, making learning holistic.",
  },
  {
    id: "inspireUs-4",
    title: "Skill-based",
    description:
      "The curriculum is designed to build artistic creativity, design thinking, and innovation. It is also deeply entrenched in our Four Markers, which helps train and empower our learners.",
  },
];

export const skillMain = [
  {
    id: "skill-1",
    title: "Sports",
  },
  {
    id: "skill-2",
    title: "Music",
  },
  {
    id: "skill-3",
    title: "Dance",
  },
  {
    id: "skill-4",
    title: "Drama & Theatre",
  },
  {
    id: "skill-5",
    title: "Art",
  },
];

export const leadershipMain = [
  {
    id: "leadership-1",
    title: "Community Outreach",
  },
  {
    id: "leadership-2",
    title: "Student Council",
  },
  {
    id: "leadership-3",
    title: "Competitions",
  },
];

export const schoolMain = [{}];
export const applytoMain = [{}];
export const storyMain = [{}];
export const infraMain = [{}];
export const infraDataMain = {};

export const markersMain = [
  {
    id: "symbiotic-1",
  },
  {
    id: "symbiotic-2",
  },
  {
    id: "symbiotic-3",
  },
  {
    id: "symbiotic-4",
  },
  {
    id: "symbiotic-5",
  },
];

export const designMain = [
  {
    id: "design-1",
  },
  {
    id: "design-2",
  },
  {
    id: "design-3",
  },
  {
    id: "design-4",
  },
];

export const environmentMain = [
  {
    id: "environment-1",
  },
  {
    id: "environment-2",
  },
  {
    id: "environment-3",
  },
  {
    id: "environment-4",
  },
];

export const wellnessMain = [
  {
    id: "wellness-1",
  },
  {
    id: "wellness-2",
  },
  {
    id: "wellness-3",
  },
  {
    id: "wellness-4",
  },
];
